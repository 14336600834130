<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow" style="background-color: white; border-radius: 10px;">
            <br>
            <div class="bodegas ">
            <ul id="lista" :key="index" v-for="(dato, index) in listaBodegas">
                <div class="container" style=";width: max-content; height: max-content;">
                    <div class="card" style="border-radius: 10px;background-color: #003A8C;cursor: pointer;" @click="irBodega(dato)">
                        <div class="contentido">
                            <h1 style="color: white;font-weight: bold;font-size: 60px;margin:30px">{{ dato.bodega }}</h1>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn mb-2" type="button" style="background-color: #003A8C;color: white;font-weight: 500;"
                v-b-modal.modal-CrearBodega>
                <span>Crear Bodega Nueva</span>
            </button>
        </div>
        </div>
        
  
        <b-modal id="modal-CrearBodega" size="lg" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color: #003A8C;font-size: x-large;font-weight: bold;">Crear Bodega Nueva</span>
            </div>
            <div class="row mb-2 mt-2">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color:#003A8C;font-weight: 700;">Bodega:</span>
                    </div>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"
                                style="border: 1px #00195A solid;background-color: #003A8C; color:white;font-weight: bold;">B</span>
                        </div>
                        <input type="text" class="form-control form-control-lg" aria-label="Username"
                            aria-describedby="basic-addon1" style="border: 1px #003A8C solid;" v-model="Bodega">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color:#003A8C;font-weight: 700;">Cantidad de Racks:</span>
                    </div>
                    <div class="input-group">
                        <input type="number" min="1" class="form-control form-control-lg" aria-label="Username"
                            aria-describedby="basic-addon1" style="border: 1px #003A8C solid;" v-model="cantRacks">
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button @click="crearListaRacks(); listaGenerada = true" class="btn mt-2"
                        style="background-color: #003A8C; color: white; font-weight: bold;">
                        <span>Generar Lista de Racks</span>
                    </button>
                </div>

                <div class="listaRacks mt-2" :key="index" v-for="(rack, index) in listaRacks"
                    style="border:1px #003A8C solid; border-radius: 10px;">
                    <div class="d-flex justify-content-center">
                        <span style="color: #003A8C;font-weight: bold;">Datos Rack {{ index + 1 }}</span>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-4">
                            <div class="d-flex justify-content-left">
                                <span style="color:#003A8C;font-weight: 700;">Cantidad de Niveles:</span>
                            </div>
                            <div class="input-group">
                                <input type="number" min="1" class="form-control form-control-lg" aria-label="Username"
                                    aria-describedby="basic-addon1" style="border: 1px #003A8C solid;"
                                    v-model="listaRacks[index].cantNiveles">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="d-flex justify-content-left">
                                <span style="color:#003A8C;font-weight: 700;">Posiciones por Niveles:</span>
                            </div>
                            <div class="input-group">
                                <input type="number" min="1" class="form-control form-control-lg" aria-label="Username"
                                    aria-describedby="basic-addon1" style="border: 1px #003A8C solid;"
                                    v-model="listaRacks[index].cantSlots">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex justify-content-left">
                                <span style="color:#003A8C;font-weight: 700;">Tipo:</span>
                            </div>
                            <div class="input-group">
                                <select class="form-control form-control-lg" v-model="listaRacks[index].tipo"
                                    style="border: 1px #003A8C solid;">
                                    <option value="0">Rack</option>
                                    <option value="1">Piso</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center" v-if="listaGenerada" style="padding: 0;">
                    <button @click="crearBodega()" class="btn mt-2"
                        style="background-color: #003A8C; color: white; font-weight: bold; width: 100%;">
                        <span>Crear Bodega</span>
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header >
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
        
    </div>
</template>

<script>

import Swal from 'sweetalert2'
import {api} from '../db'

export default {
    name: 'Etiquetas',
    data: function () {
        return {
            bodega: null, cantRacks: 1, listaRacks: [], listaGenerada: false,
            listaBodegas: [], listadoCrearRacks: [],
            generado: false, ubicacion: null, Bodega: null
        }
    },
    components: {
    },
    methods: {
        
        obtenerBodegas() {
            const url = api + 'bodegas/'
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    res.json().then(data => {
                        const listaOrdenada = data.sort((a, b) => {
                            const nombreA = a.bodega.toUpperCase();
                            const nombreB = b.bodega.toUpperCase();

                            if (nombreA < nombreB) {
                                return -1;
                            }
                            if (nombreA > nombreB) {
                                return 1;
                            }
                            return 0;
                        });
                        this.listaBodegas = listaOrdenada
                    })
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        },
        async crearListaRacks() {
            this.listaRacks = []
            this.listadoCrearRacks = []


            const cantRacks = this.cantRacks
            for (let i = 0; i < cantRacks; i++) {
                const obj = {
                    cantNiveles: 1,
                    cantSlots: 1,
                    tipo: 0
                }
                this.listaRacks.push(obj)
            }
        },
        async crearBodega() {
            this.$bvModal.show('modal-Cargando')
            const lista = this.listaRacks
            var bodega = this.Bodega
            if(bodega.length < 2){
                bodega = '0'+bodega
            }

            bodega = 'B'+bodega
            const datos = {
                bodega: bodega,
                listaRacks: lista
            }

            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(datos)
            }

            const url = api + 'bodegas'

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Error en la solicitud');
                    }
                    res.json().then(data => {
                        if (data.codigo == 0) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Registrado con Éxito'
                            })
                            this.$bvModal.hide('modal-Cargando')
                            this.$bvModal.hide('modal-CrearBodega')
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: data.error,
                                text: 'Contactar con Desarrollo'
                            })
                        }
                        this.obtenerBodegas()
                        
                    })
                })
        },
        irBodega(dato){
            sessionStorage.setItem("bodega",dato.bodega)
            this.$router.push('/bodega')
        },
        cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if(tipoUsuario != 0){
            window.alert('Debe iniciar sesión')
            this.$router.push('/')
        }
        this.obtenerBodegas()
    }
}
</script>

<style>


/* Estilo base de la lista */
.bodegas {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: 1rem;
    grid-auto-flow: dense;
    grid-auto-rows: 10rem;
}

#lista {
    padding: 0;
}

</style>