<script setup>
import { ref } from 'vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import {api} from '../db'

const bodega = ref(null);
const rack = ref(null);
const posicion = ref(null);
const selectPosicion = ref(null);
const selectRack = ref(null)
const pallet = ref(null)

const onLoaded = () => {
    console.log('loaded');
};

const onDecode = (text) => {
    console.log(text);
    const listaDatos = text.split('-')
    if (listaDatos.length === 3) {
        const valorBodega = listaDatos[0].slice(1)
        const valorRack = listaDatos[1].slice(1)
        const valorPosicion = listaDatos[2].slice(1)
        const letraRack = listaDatos[1]
        const letraPosicion = listaDatos[2]
        bodega.value.value = valorBodega
        rack.value.value = valorRack
        posicion.value.value = valorPosicion
        selectRack.value.value = letraRack[0]
        selectPosicion.value.value = letraPosicion[0]

        const url = api +'bodegas/posicion/B' + valorBodega + '/' + letraRack  + '/' + letraPosicion 
        const reqOpciones = {
            methods: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: sessionStorage.getItem('token')   
            },
        }
        fetch(url, reqOpciones)
            .then(res => {
                res.json().then(data => {
                    if(data.length > 0){
                        pallet.value.value = data[0].idPallet
                    }else{
                        pallet.value.value = null
                    }
                })
            }).catch(err => {
                console.error(`Error: ${err}`)
            })


    } else if (listaDatos.length === 1) {
        if (/^\d+$/.test(text)) {
            pallet.value.value = text
        }
    }
};

</script>

<template>
    <div>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
        <div class="container-fluid col-md-11 " style="background-color: white;border-radius: 10px 10px 0 0;">
            <div class="row">
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group input-group-lg">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-lg"
                                style="background-color: #003A8C;color:white;font-weight: 700;">B</span>
                        </div>
                        <input required type="number" id="bodega" ref="bodega" class="form-control" aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm" style="border: 1px #003A8C solid;">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group input-group-lg">
                        <div class="input-group-prepend">
                            <select required class="form-control form-control-lg" id="selectRack" ref="selectRack"
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="R">R</option>
                                <option value="P">P</option>
                            </select>
                        </div>
                        <input required id="rack" type="number" ref="rack" class="form-control" aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm" style="border: 1px #003A8C solid;">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group input-group-lg mb-3">
                        <div class="input-group-prepend">
                            <select required class="form-control form-control-lg" id="selectPosicion" ref="selectPosicion"
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                            </select>
                        </div>
                        <input required id="posicion" type="number" ref="posicion" class="form-control"
                            aria-label="Text input with dropdown button" style="border: 1px #003A8C solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex align-items-center mb-3" style="height: 100%;">
                        <span style="color: #003A8C; font-size: large; font-weight: 700;">Pallet:</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <input required id="pallet" type="number" ref="pallet" class="form-control mb-3"
                        style="border: 1px #003A8C solid;">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex align-items-center" style="height: 100%;">
                        <span style="color: #003A8C; font-size: large; font-weight: 700;">Tipo de Movimiento:</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <select class="form-control mb-2" id="selectTipoMov" ref="selectTipoMov" required
                        style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                        <option value="no" selected hidden>Seleccione una Opción</option>
                        <option value="EP">Entrada</option>
                        <option value="SM">Salida</option>
                        <option value="IM">Reposición</option>
                        <option value="RF">Despacho Cliente</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex align-items-center mb-2" style="height: 100%;">
                        <span style="color: #003A8C; font-size: large; font-weight: 700;">Comentario (opcional):</span>
                    </div>
                </div>
                <div class="col-md-8">
                    <textarea ref="comentario" class="form-control mb-3" id="comentario" rows="3" 
                        style="border:1px #003A8C solid;background-color: white;">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</template>
