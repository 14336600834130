<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="container-fluid col-md-11 shadow d-print-none" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Bodega {{ bodega }}</span>
            </div>
            <div class="mt-2" :key="index" v-for="(racks, index) in listaRacks">
                <div class="d-flex justify-content-center">
                    <span style="color:#003A8C;font-weight: 700;font-size: large;">{{ racks[0].rack }}</span>
                </div>
                <div class="table-responsive" style="border-radius: 10px;">
                    <table class="table table-striped table-hover mb-3">
                        <thead style="position: sticky; top:0">
                            <th>Id</th>
                            <th>Ubicacion</th>
                            <th>Pallet</th>
                            <th>SKU</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Lote Mimbral</th>
                            <th>Fecha Vencimiento</th>
                            <th>Imprimir</th>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(almacenamientos, index) in racks">
                                <td>{{ almacenamientos.id }}</td>
                                <td>{{ almacenamientos.ubicacion }}</td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.idPallet }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.sku }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.descripcion }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.cantidad }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.idLoteMimbral }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="almacenamientos.tipoMovimiento === 'EP'">
                                        {{ almacenamientos.fechaVencimiento.split('T')[0] }}
                                    </span>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="btn" type="button" @click="Imprimir(almacenamientos)" style="background-color: #003A8C;color:white">
                                            <b-icon icon="printer-fill" aria-hidden="true"></b-icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
        <div class="d-print-block d-none">

            <br><br><br><br><br><br><br><br>
        </div>
        <div id="zonaImpresion" class=" d-none d-print-block" style="width: 100%;transform: rotate(90deg);">
            
            <div id="zona" class="d-flex justify-content-center">

                <vue-qr id="qr" :text="final" :size="1000">
                </vue-qr>

            </div>
            <div class="d-flex justify-content-center">
                <span id="ubi" style="color: black;font-weight: bold; text-align: center;">
                    {{ ubicacion }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import vueQr from 'vue-qr'
import {api} from '../db'

export default {
    name: 'Etiquetas',
    data: function () {
        return {
            bodega: null,
            listaRacks: [],
            final: '', ubicacion: null,

        }
    },
    components: {
        vueQr
    },
    methods: {
        async generarQR(dato) {
            this.final = dato
            this.ubicacion = dato
            return 0
        },
        async Imprimir(almacenamientos) {
            const bodega = almacenamientos.bodega
            const rack = almacenamientos.rack
            const ubicacion = almacenamientos.ubicacion
            const final = `${bodega}-${rack}-${ubicacion}`
            await this.generarQR(final)
            window.print()
        },
        async obtenerRacks() {
            this.$bvModal.show('modal-Cargando')
            const url = api + 'bodegas/' + this.bodega
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }

            fetch(url, reqOpciones)
                .then(res => {
                    res.json().then(data => {
                        this.listaRacks = data
                        this.$bvModal.hide('modal-Cargando')
                    })
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        },
        cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
        
    },
    mounted() {
        this.bodega = sessionStorage.getItem('bodega')
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if(tipoUsuario != 0){
            window.alert('Debe iniciar sesión')
            this.$router.push('/')
        }
        this.obtenerRacks()
    }
}
</script>
<style>
@media print {
    #zona {
        justify-content: center;
        align-items: center;
    }

    #qr {
        size: 1000px;
    }

    #ubi {
        font-size: 145px;
    }
}

</style>