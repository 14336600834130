import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBZX73ja1CMt4WTpl7xgooPL_Qj5H0RRFk",
  authDomain: "sistemabodegasmimbral.firebaseapp.com",
  projectId: "sistemabodegasmimbral",
  storageBucket: "sistemabodegasmimbral.appspot.com",
  messagingSenderId: "119336690185",
  appId: "1:119336690185:web:981425a2dd5242b8648ed8",
  measurementId: "G-LLZCV59FNT"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const an = firebase.analytics(firebaseApp) 
export const db = firebaseApp.firestore();
export const storage = firebase.storage();