<template>
    <div class="content">
        <nav v-if="tipoUsuario == 0" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav v-if="tipoUsuario == 2" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeRecepcion">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Lotes Mimbral</span>
            </div>
            <div class="d-flex justify-content-left">
                <span style="color:#003A8C;font-weight: bold;font-size: large;">Buscador:</span>
            </div>
            <div class="d-flex justify-content-center">
                <div class="input-group mb-3 ">
                    <div class="input-group-prepend">
                        <select class="form-control" id="selectMetodo" ref="selectMetodo" v-model="tipoBusqueda"
                            style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                            <option selected hidden value="no">Método</option>
                            <option value="0">Lote</option>
                            <option value="1">SKU</option>
                            <option value="2">Descripción</option>

                        </select>
                    </div>
                    <input type="text" class="form-control" style="border: 1px #003A8C solid" v-model="valorBusqueda">
                    <div class="input-group-append">
                        <button class="btn" @click="buscar()"
                            style="border-radius: 1px #003A8C solid; background-color: #003A8C;color:white">
                            <b-icon icon="search" aria-hidden="true"></b-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-bordered table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Lote</th>
                        <th>SKU</th>
                        <th>Lote del Proveedor</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Fecha de Vencimiento</th>
                        <th>Fecha de Repeción</th>
                        <th>Registrador</th>
                        <th>Pallets</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Lotes">
                            <td>{{ datos.lote_id }}</td>
                            <td>{{ datos.sku }}</td>
                            <td>{{ datos.loteProveedor }}</td>
                            <td>{{ datos.descripcion }}</td>
                            <td>{{ datos.cantidad }}</td>
                            <td>{{ datos.fechaVencimiento }}</td>
                            <td>{{ datos.fechaRecepcion }}</td>
                            <td>{{ datos.nombre }}</td>
                            <td>
                                <div class="flex justify-content-center align-items-center">
                                    <button @click="irPallet(datos.lote_id)" class="btn"
                                        style="background-color: #003A8C;color:white">
                                        <b-icon icon="view-stacked"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn mb-2" v-b-modal.modal-crearLote
                    style="background-color: #003A8C;color:white;font-weight: bold; width: 100%;">
                    Crear Lote
                </button>
            </div>
        </div>
        <b-modal id="modal-crearLote" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Crear Lote</span>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Orden de Compra</span>
                    </div>
                    <div class="input-group">
                        <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="ordenCompra">
                        <div class="input-group-append">
                            <button class="btn" @click="buscarOC()"
                                style="border-radius: 1px #003A8C solid; background-color: #003A8C;color:white">
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <form @submit.prevent="registrarLote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">SKU</span>
                        </div>
                        <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="sku" required minlength="9">
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Descripción</span>
                        </div>
                        <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="Descripcion" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Cantidad</span>
                        </div>
                        <input type="number" min="0" style="background-color: white;border:1px #003A8C solid;"
                            class="form-control " v-model="Cantidad" required>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Lote del Proveedor</span>
                        </div>
                        <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="loteProveedor">
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Fecha de Recepción</span>
                        </div>
                        <input type="date" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="fechaRecepcion" required>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color:#003A8C;font-weight: 600;">Fecha de Vencimiento</span>
                        </div>
                        <input type="date" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                            v-model="fechaVencimiento">
                    </div>
                </div>
                <div class="d-flex justify-content-center mb-2 mt-2">
                    <button class="btn" type="submit"
                        style="border-radius: 1px #003A8C solid; width: 100%;background-color: #003A8C;color:white;font-weight: bold;">
                        Registrar Lote
                    </button>
                </div>
            </form>
        </b-modal>
        <b-modal id="modal-Productos" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Seleccione un Producto</span>
            </div>
            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-bordered table-hover">
                    <thead style="position: sticky; top:0">
                        <th>SKU</th>
                        <th>Descripción</th>
                        <th>Proveedor</th>
                        <th>Cantidad</th>
                        <th>Unidad</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in productosCompra" >
                            <td @click="pasoDatos(datos)"> {{ datos.itemcode }}</td>
                            <td @click="pasoDatos(datos)">{{ datos.Dscription }}</td>
                            <td @click="pasoDatos(datos)">{{ datos.Quantity }}</td>
                            <td @click="pasoDatos(datos)">{{ datos.CardName }}</td>
                            <td @click="pasoDatos(datos)">{{ datos.unitMsr }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>

<script>

import { api } from '../db'
import Swal from 'sweetalert2'

export default {
    name: 'HomeRecepcion',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            tipoUsuario: 0, Pallets: [], Lotes: [],
            tipoBusqueda: 'no', valorBusqueda: null,
            ordenCompra: null, sku: null, Descripcion: null,
            Cantidad: null, fechaVencimiento: null, fechaRecepcion: null,
            loteProveedor: null, productosCompra: []
        }
    },
    methods: {
        async irPallet(lote) {
            sessionStorage.setItem('lote', lote)
            this.$router.push('/pallets')
        },
        async buscarLotes() {
            this.$bvModal.show('modal-Cargando')

            this.Lotes = []

            const url = api + 'lotes/'
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    data.forEach(element => {
                        if (element.fechaVencimiento) {
                            const fecha = element.fechaVencimiento.split('T')
                            element.fechaVencimiento = fecha[0]
                        }
                        if (element.fechaRecepcion) {
                            const fecha2 = element.fechaVencimiento.split('T')
                            element.fechaRecepcion = fecha2[0]
                        }
                        this.Lotes.push(element)
                    })
                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        async buscar() {
            this.$bvModal.show('modal-Cargando')
            this.Lotes = []

            const tipoBusqueda = this.tipoBusqueda
            const valorBusqueda = this.valorBusqueda

            var url = api + 'lotes/'
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            } 
            if(tipoBusqueda == 0){
                url = url + 'lote/' + valorBusqueda

            }else if(tipoBusqueda == 1){
                url = url + 'sku/' + valorBusqueda
            } else if(tipoBusqueda == 2){
                url = url + 'descripcion/' + valorBusqueda
            }else{
                Swal.fire({
                    icon:'info',
                    title: 'Seleccione un método de busqueda'
                })
                return
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    data.forEach(element => {
                        if (element.fechaVencimiento) {
                            const fecha = element.fechaVencimiento.split('T')
                            element.fechaVencimiento = fecha[0]
                        }
                        if (element.fechaRecepcion) {
                            const fecha2 = element.fechaVencimiento.split('T')
                            element.fechaRecepcion = fecha2[0]
                        }
                        this.Lotes.push(element)
                    })
                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        async buscarOC() {
            this.productosCompra = []
            this.$bvModal.show('modal-Cargando')
            const oc = this.ordenCompra

            // const url = sap + oc
            // const reqOpciones = {
            //     methods: 'GET',
            //     mode: 'no-cors'
            // }

            fetch(`https://apisapmimbral.loclx.io/querybodega/${oc}`)
                    .then((response) => {
                        if (!response.ok) {
                        throw new Error('La solicitud no tuvo éxito.');
                        }
                        return response.json(); 
                    })
                    .then((data) => {
                       this.productosCompra = data.recordset
                       console.log(this.productosCompra)
                       this.$bvModal.hide('modal-Cargando')
                       this.$bvModal.show('modal-Productos')
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });

        },
        async pasoDatos(datos){
            this.sku = datos.itemcode
            this.Descripcion = datos.Dscription
            this.Cantidad = datos.Quantity
            this.$bvModal.hide('modal-Productos')
        },
        async registrarLote() {
            this.$bvModal.show('modal-Cargando')
            const regex = /^[0-9]+$/;
            const skuValido = regex.test(this.sku)

            if (!skuValido) {
                Swal.fire({
                    icon: 'warning',
                    title: 'SKU no Válido'
                })
                return
            }

            const datos = {
                sku: this.sku,
                descripcion: this.Descripcion,
                loteProveedor: this.loteProveedor,
                fechaVencimiento: this.fechaVencimiento,
                cantidad: this.Cantidad,
                fechaRecepcion: this.fechaRecepcion
            }

            const url = api + 'lotes/'

            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Error en la solicitud');
                    }
                    Swal.fire({
                        icon: 'success',
                        title: 'Registrado con éxito'
                    })
                    this.$bvModal.hide('modal-Cargando')
                    this.buscarLotes()
                }).catch(error => {
                    console.error(error);
                })
        },
        cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if (tipoUsuario == 0 || tipoUsuario == 2) {

            this.tipoUsuario = tipoUsuario
            this.buscarLotes()
        } else {
            window.alert('No tiene acceso')
            this.$router.push('/')
        }
    }
}
</script>

<style></style>