<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="container-fluid col-md-11 shadow" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Movimientos del día</span>
            </div>

            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-bordered table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Pallet</th>
                        <th>Bodega</th>
                        <th>Rack</th>
                        <th>Posición</th>
                        <th>Tipo Movimiento</th>
                        <th>Usuario</th>
                        <th>Comentario Movimiento</th>
                        <th>Aprobación</th>

                        <th>Comentario Aprobación</th>
                        <th>Detalles</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Resultado">
                            <td>{{ datos.idPallet }}</td>
                            <td>{{ datos.bodega }}</td>
                            <td>{{ datos.rack }}</td>
                            <td>{{ datos.ubicacion }}</td>
                            <td>{{ datos.tipo }}</td>
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.comentario_movimiento }}</td>
                            <td style="width: max-content;">
                                <div v-if="datos.tipo === 'Descuento'"></div>
                                <div v-else-if="datos.estado == 0" class="d-flex justify-content-center">
                                    <button class="btn" style="background-color: #003A8C; color: white;"
                                        @click="pasoDatos(datos)" v-b-modal.modal-Aprobar>
                                        <b-icon icon="gear" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                                <div v-else-if="datos.estado == 1" class="d-flex justify-content-center">
                                    <span>
                                        <b-icon icon="check-lg" aria-hidden="true" style="color: green;"></b-icon>
                                    </span>
                                </div>
                                <div v-else-if="datos.estado == 2" class="d-flex justify-content-center">
                                    <span>
                                        <b-icon icon="x-lg" aria-hidden="true" style="color: red;"></b-icon>
                                    </span>
                                </div>

                            </td>
                            <td>{{ datos.comentario_aprobacion }}</td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <button class="btn" v-b-modal.modal-Detalles @click="pasoDatos(datos)"
                                        style="background-color: #003A8C; color:white">
                                        <b-icon icon="eye" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-modal d-print-none id="modal-Aprobar" size="lg" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="font-weight: bold;font-size: x-large;color: #003A8C;">Aprobación</span>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Nombre</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.nombre">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Rut</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.idUsuario">
                </div>

            </div>
            <div class="d-flex justify-content-center">
                <span style="color: #003A8C;font-weight: 600;">Posición</span>
            </div>
            <div class="row">
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-lg"
                                style="background-color: #003A8C;color:white;font-weight: 700;border: 1px #003A8C solid;">B</span>
                        </div>
                        <input required type="number" id="bodega" ref="bodega" class="form-control" aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm" style="border: 1px #003A8C solid;"
                            v-model="datosMovimiento.bodega">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <select required class="form-control" id="selectRack" ref="selectRack"
                                v-model="datosMovimiento.letraRack"
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="R">R</option>
                                <option value="P">P</option>
                            </select>
                        </div>
                        <input required id="rack" type="number" ref="rack" class="form-control" aria-label="Large"
                            v-model="datosMovimiento.valorRack" aria-describedby="inputGroup-sizing-sm"
                            style="border: 1px #003A8C solid;">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <select required class="form-control" id="selectPosicion" ref="selectPosicion"
                                v-model="datosMovimiento.letraUbicacion"
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                            </select>
                        </div>
                        <input required id="posicion" type="number" ref="posicion" class="form-control"
                            v-model="datosMovimiento.valorUbicacion" aria-label="Text input with dropdown button"
                            style="border: 1px #003A8C solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Fecha</span>
                    </div>
                    <input type="date" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.fecha">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Tipo Movimiento</span>
                    </div>
                    <select disabled class="form-control form-contro-lg" id="selectTipoMov" ref="selectTipoMov"
                        v-model="datosMovimiento.tipo" readonly
                        style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                        <option selected hidden>Seleccione una Opción</option>
                        <option value="EP">EP</option>
                        <option value="SM">SM</option>
                        <option value="IM">IM</option>
                        <option value="RF">RF Cliente</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Pallet</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.idPallet">
                </div>
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Lote Mimbral</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.loteMimbral">
                </div>
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Cantidad</span>
                    </div>
                    <input type="number" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.cantidad">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">SKU</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.sku">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Descripción</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.descripcion">
                </div>
            </div>
            <div class="d-flex justify-content-left">
                <span style="color: #003A8C;font-weight: 600;">Comentario (opcional)</span>
            </div>
            <textarea v-model="comentario" class="form-control" id="exampleFormControlTextarea1" rows="3"
                style="border:1px #003A8C solid;background-color: white;"></textarea>
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col-md-6">
                        <button @click="aprobar(true)" class="btn mt-1" style="background-color: green; color:white">
                            <b-icon icon="check-lg" aria-hidden="true"></b-icon>
                            <span>Aprobado</span>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button @click="aprobar(false)" class="btn mt-1" style="background-color: red; color:white">
                            <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                            <span>Rechazado</span>
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal d-print-none id="modal-Detalles" size="lg" hide-footer>
            <div class="d-flex justify-content-center">
                <span style="font-weight: bold;font-size: x-large;color: #003A8C;">Detalles</span>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Nombre</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.usuario">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Rut</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.rut">
                </div>

            </div>
            <div class="d-flex justify-content-center">
                <span style="color: #003A8C;font-weight: 600;">Posición</span>
            </div>
            <div class="row">
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="inputGroup-sizing-lg"
                                style="background-color: #003A8C;color:white;font-weight: 700;border: 1px #003A8C solid;">B</span>
                        </div>
                        <input required type="number" id="bodega" ref="bodega" class="form-control" aria-label="Large"
                            readonly aria-describedby="inputGroup-sizing-sm" style="border: 1px #003A8C solid;">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group ">
                        <div class="input-group-prepend">
                            <select required class="form-control" id="selectRack" ref="selectRack" readonly
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="R">R</option>
                                <option value="P">P</option>
                            </select>
                        </div>
                        <input required id="rack" type="number" ref="rack" class="form-control" aria-label="Large" readonly
                            aria-describedby="inputGroup-sizing-sm" style="border: 1px #003A8C solid;">
                    </div>
                </div>
                <div class="col-4" style="margin-top: 1%;">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <select required class="form-control" id="selectPosicion" ref="selectPosicion" readonly
                                style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                            </select>
                        </div>
                        <input required id="posicion" type="number" ref="posicion" class="form-control" readonly
                            aria-label="Text input with dropdown button" style="border: 1px #003A8C solid;">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Fecha</span>
                    </div>
                    <input type="date" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.fecha">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Tipo Movimiento</span>
                    </div>
                    <select disabled class="form-control form-contro-lg" id="selectTipoMov" ref="selectTipoMov"
                        v-model="datosMovimiento.tipo"
                        style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                        <option selected hidden>Seleccione una Opción</option>
                        <option value="EP">EP</option>
                        <option value="SM">SM</option>
                        <option value="IM">IM</option>
                        <option value="RF">RF Cliente</option>
                        <option value="Descuento">Descuento</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Pallet</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.idPallet">
                </div>
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Lote Mimbral</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.loteMimbral">
                </div>
                <div class="col-md-4">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Cantidad</span>
                    </div>
                    <input type="number" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.cantidad">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">SKU</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.sku">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Descripción</span>
                    </div>
                    <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                        readonly v-model="datosMovimiento.descripcion">
                </div>
            </div>
        </b-modal>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { api } from '../db'
import { db } from '../firebase'

export default {
    name: 'HomeAdmin',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            TipoUsuario: 0, parametroBusqueda: null,
            Resultado: [], buscado: false,
            datosMovimiento: {}, comentario: null,
            websocket: null
        }
    },
    methods: {
        buscarMovimientos() {
            const url = api + 'movimientos/dia'
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                    Authorization: sessionStorage.getItem('token')
                },
            }
            db.collection('cambios').onSnapshot(() => {
                this.$bvModal.show('modal-Cargando')

                this.Resultado = []

                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            throw new Error('Error en la base de datos');
                        }
                        return res.json()
                    })
                    .then(data => {
                        this.Resultado = data
                        console.log(data)
                        this.$bvModal.hide('modal-Cargando')

                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })
            })

        },
        aprobar(aprobacion) {
            this.$bvModal.show('modal-Cargando')
            const estado = (aprobacion) ? 1 : 2;
            const obj = {
                idAprobacion: this.datosMovimiento.idAprobacion,
                idUsuario: this.datosMovimiento.idUsuario,
                estado: estado,
                comentario: this.comentario
            }
            console.log(sessionStorage.getItem('token'))
            const url = api + 'aprobacion'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    
                Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(obj)
            }

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.code === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registrado con exito'
                        })
                    }
                    db.collection('cambios').add(obj).then(() => {

                        this.$bvModal.hide('modal-Cargando')
                        this.$bvModal.hide('modal-Aprobar')
                    })
                    // this.buscarMovimientos()
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        pasoDatos(datos) {
            var datosMovimiento = datos
            datosMovimiento.bodega = datosMovimiento.bodega.slice(1)
            datosMovimiento.letraRack = datosMovimiento.rack.charAt(0)
            datosMovimiento.valorRack = datosMovimiento.rack.slice(1)
            datosMovimiento.letraUbicacion = datosMovimiento.ubicacion.charAt(0)
            datosMovimiento.valorUbicacion = datosMovimiento.ubicacion.slice(1)
            const listaFecha = datosMovimiento.fecha.split('T')
            datosMovimiento.fecha = listaFecha[0]
            this.datosMovimiento = datosMovimiento
        },
        cerrarSesion() {
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if (tipoUsuario != 0) {
            window.alert('Debe ser Administrador')
            this.$router.push('/')
        }
        this.buscarMovimientos()
    },
}
</script>

<style>
th {
    background-color: #003A8C;
    color: white;
    border: 1px white solid;
}

td {
    font-weight: 500;
}
</style>