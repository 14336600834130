
<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import CameraScanner from "../components/CameraScanner.vue";

</script>

<template>
    <div class="content">
        <nav class="navbar navbar-expand-lg d-print-none" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/RegistrarMovimiento">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <CameraScanner />
        <div class="container-fluid col-md-11" style="background-color: white; border-radius: 0 0 10px 10px;">
            <div class="d-flex justify-content-center mb-3">
                <button @click="GuardarMovimiento" class="btn mb-2"
                    style="color: white;background-color: #003A8C;font-weight: bold;">Guardar
                    Movimiento</button>
            </div>
        </div>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>
  

<script>

import Swal from "sweetalert2";
import { api } from "../db";
import { db } from "../firebase";

export default {
    name: 'Participantes',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
        }
    },
    methods: {
        GuardarMovimiento() {

            //referencias
            const bodegaInput = document.getElementById('bodega');
            const selectRackInput = document.getElementById('selectRack');
            const rackInput = document.getElementById('rack');
            const selectPosicionInput = document.getElementById('selectPosicion');
            const posicionInput = document.getElementById('posicion');
            const palletInput = document.getElementById('pallet');
            const selectTipoMovInput = document.getElementById('selectTipoMov');
            const comentarioInput = document.getElementById('comentario')

            //obtener valores
            var valorBodega = bodegaInput.value;
            var letraRack = selectRackInput.value;
            var valorRack = rackInput.value;
            var letraPosicion = selectPosicionInput.value;
            var valorPosicion = posicionInput.value;
            const valorPallet = palletInput.value;
            const tipoMovimiento = selectTipoMovInput.value;
            const comentario = comentarioInput.value

            if (tipoMovimiento === 'no') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Ingrese Tipo de Movimiento'
                })
            } else {

                this.$bvModal.show('modal-Cargando')

                if (valorBodega.length === 1) {
                    valorBodega = '0' + valorBodega;
                }
                valorBodega = 'B' + valorBodega;

                if (valorRack.length === 1) {
                    valorRack = '0' + valorRack;
                }
                valorRack = letraRack + valorRack;

                if (valorPosicion.length === 1) {
                    valorPosicion = '0' + valorPosicion;
                }
                valorPosicion = letraPosicion + valorPosicion;

                console.log(`Bodega: ${valorBodega} Rack: ${valorRack} Posicion: ${valorPosicion}`);
                console.log(`Pallet: ${valorPallet} TipoMovimiento: ${tipoMovimiento}`);
                console.log(`Comentario: ${comentario}`)
                //aca registra el movimiento y con un trigger debe agregarlo en la tabla de aprobacion
                const obj = {
                    idPallet: valorPallet,
                    tipo: tipoMovimiento,
                    bodega: valorBodega,
                    rack: valorRack,
                    ubicacion: valorPosicion,
                    comentario: comentario
                }
                const url = api + 'movimientos'
                const reqOpciones = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                        Authorization: sessionStorage.getItem('token')
                    },
                    body: JSON.stringify(obj),
                }

                fetch(url, reqOpciones)
                    .then(res => {
                        res.json().then(data => {
                            if (data.code === 0) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Registrado con éxito'
                                })
                                db.collection('cambios').add(obj).then(()=>{
                                    
                                this.$bvModal.hide('modal-Cargando')
                                })
                            }
                            else if (data.code === 2) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Ubicación no registrada'
                                })
                                this.$bvModal.hide('modal-Cargando')
                            }
                            else if (data.code === 3) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Pallet no registrado'
                                })
                                this.$bvModal.hide('modal-Cargando')
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error al ingresar movimiento',
                                    text: 'Avisar a encargado'
                                })
                            }
                            bodegaInput.value = null
                            selectRackInput.value = 'R'
                            rackInput.value = null
                            selectPosicionInput.value = 'A'
                            posicionInput.value = null
                            palletInput.value = null
                            selectTipoMovInput.value = 'Seleccione una Opción'
                            comentarioInput.value = null
                        })
                    })
            }
        },
        
        cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        const tieneAcceso = (tipoUsuario == 0 || tipoUsuario == 1) ? true : false

        if (!tieneAcceso) {
            window.alert('No tiene acceso')
            this.$router.push('/')
        }
    }
}
</script>

<style></style>