<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav v-if="tipoUsuario == 0" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav v-if="tipoUsuario == 2" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeRecepcion">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-11 d-print-none shadow" style="background-color: white;border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Pallets de Lote: {{ Lote }}</span>
            </div>
            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-bordered table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Pallet</th>
                        <th>Stock</th>
                        <th>Designado Incial</th>
                        <th>Fecha Registro</th>
                        <th>Imprimir</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Resultado">
                            <td>{{ datos.pallet_id }}</td>
                            <td>{{ datos.cantidad }}</td>
                            <td>{{ datos.designadoInicial }}</td>
                            <td>{{ datos.fechaRegistro }}</td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <button class="btn" v-b-modal.modal-Detalles @click="generarQR(datos.pallet_id)"
                                        style="background-color: #003A8C; color:white">
                                        <b-icon icon="printer" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <button class="btn mt-2 mb-2" v-b-modal.modal-crearPallet
                        style="background-color: #003A8C; color: white; font-weight: bold; width: 100%;">
                        <span>Crear Pallet</span>
                    </button>
                </div>
                <div class="col-md-6">

                    <button class="btn mt-2" @click="buscarOtrosPallets()"
                        style="background-color: #003A8C; color: white; font-weight: bold; width: 100%;">
                        <span>Asignar Pallet</span>
                    </button>
                </div>
            </div>

        </div>
        <div class="d-none d-print-block">
            <br><br><br><br><br><br><br><br>
        </div>
        <div id="zonaImpresion" class="d-print-block d-none">

            <div id="zona" class="d-flex justify-content-center">

                <vue-qr id="qr" :text="final" :size="1000">
                </vue-qr>

            </div>
            <div class="d-flex justify-content-center">
                <span id="ubi" style="color: black;font-weight: bold; text-align: center;">
                    {{ pallet }}</span>
            </div>
        </div>
        <b-modal id="modal-crearPallet" size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Crear Pallets</span>
            </div>
            <div class="d-flex justify-content-left">
                <span style="color: #003A8C;font-weight: 600;">Cantidad de pallets:</span>
            </div>
            <div class="input-group mb-3">
                <input type="number" min="1" style="background-color: white;border:1px #003A8C solid;" class="form-control "
                    v-model="cantPallet" required>
                <div class="input-group-append">
                    <button class="btn" @click="crearListaPallets(); listaGenerada = true"
                        style="background-color: #003A8C;color:white;font-weight: bold;">
                        Generar Lista de Pallets
                    </button>
                </div>
            </div>
            <div v-if="listaGenerada">
                <div class="listaRacks mb-3" :key="index" v-for="(pallet, index) in listaPallets"
                    style="border:1px #003A8C solid; border-radius: 10px; padding-left: 1%;padding-right: 1%;">
                    <div class="d-flex justify-content-left mt-2">
                        <span style="color: #003A8C;font-weight: bold;">Pallet {{ index + 1 }}</span>
                    </div>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"
                                style="background-color: #003A8C;color:white;font-weight: bold;border: #003A8C 1px solid;">Cantidad</span>
                        </div>
                        <input type="number" v-model="listaPallets[index].cantidad" class="form-control"
                            aria-label="Username" aria-describedby="basic-addon1" style="border: #003A8C 1px solid;">
                    </div>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn" @click="crearPallets()"
                    style="background-color: #003A8C;color:white;font-weight: bold;">
                    Registrar Pallets
                </button>
                </div>
                
            </div>
        </b-modal>
        <b-modal id="modal-asignarPallet" size="xl" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Asignar Pallets</span>
            </div>
            <div class="input-group mb-3 " v-if="!seleccionado">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-lg"
                        style="background-color: #003A8C;color:white;font-weight: 700;border:#003A8C 1px solid">Pallet:
                    </span>
                </div>
                <input type="number" min="1" class="form-control" style="border: 1px #003A8C solid" v-model="palletBuscar">
                <div class="input-group-append">
                    <button class="btn" @click="buscarOtroPallet()"
                        style="border-radius: 1px #003A8C solid; background-color: #003A8C;color:white">
                        <b-icon icon="search" aria-hidden="true"></b-icon>
                    </button>
                </div>
            </div>

            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;" v-if="!seleccionado">
                <table class="table table-bordered table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Pallet</th>
                        <th>Stock</th>
                        <th>Otro lote</th>
                        <th>Seleccionar</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in otrosPallets">
                            <td>{{ datos.pallet_id }}</td>
                            <td>{{ datos.cantidad }}</td>
                            <td>{{ datos.lote_id }}</td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <button class="btn" @click="seleccionar(datos)"
                                        style="background-color: #003A8C; color:white">
                                        <b-icon icon="check" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" v-if="seleccionado">
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Pallet:</span>
                    </div>
                    <input type="number" v-model="palletAsignar" class="form-control"
                        style="border: #003A8C 1px solid;">
                </div>
                <div class="col-md-6">
                    <div class="d-flex justify-content-left">
                        <span style="color: #003A8C;font-weight: 600;">Stock:</span>
                    </div>
                    <input type="number" min="1" v-model="cantAsignar" class="form-control"
                        style="border: #003A8C 1px solid;">
                </div>
            </div>
            <div class="d-flex justify-content-center" v-if="seleccionado">
                <button class="btn mt-2" @click="Asignar()"
                    style="background-color: #003A8C; color: white; font-weight: bold; width: 100%;">
                    Asginar Pallet al Lote
                </button>
            </div>
        </b-modal>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>

<script>
import { api } from '../db'
import vueQr from 'vue-qr'
import Swal from 'sweetalert2'

export default {
    name: 'Etiquetas',
    data: function () {
        return {
            pallet: null, Resultado: [], tipoUsuario: null,
            final: "", Lote: null, cantPallet: null,
            listaGenerada: false, otrosPallets: [],
            palletBuscar: null, seleccionado: false,
            palletAsignar: null, cantAsignar:null
        }
    },
    components: {
        vueQr
    },
    methods: {
        async buscarPallets() {
            this.Resultado = []
            this.$bvModal.show('modal-Cargando')
            const lote = this.Lote
            const url = api + 'pallets/' + lote
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {

                    if (data.length === 0) {
                        this.$bvModal.hide('modal-Cargando')
                    } else {
                        data.forEach(element => {
                            if (element.fechaRegistro) {
                                const fecha = element.fechaRegistro.split('T')
                                element.fechaRegistro = fecha[0]

                            }
                            this.Resultado.push(element)
                        });
                        this.$bvModal.hide('modal-Cargando')
                    }
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        generarQR(pallet) {
            this.final = pallet.toString()
            this.pallet = pallet
            setTimeout(function () {
                window.print()
            }, 1000);
        },
        crearListaPallets() {
            this.listaPallets = []

            const cantPallets = this.cantPallet
            for (let i = 0; i < cantPallets; i++) {
                const obj = {
                    cantidad: null
                }
                this.listaPallets.push(obj)
            }
        },
        crearPallets() {
            this.$bvModal.hide('modal-crearPallet')
            this.$bvModal.show('modal-Cargando')

            const datos = {
                listaPallets: this.listaPallets,
                idLote: this.Lote
            }
            const reqOpciones = {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(datos)
            }
            const url = api + 'pallets/'

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Error en la solicitud');
                    }
                    res.json().then(data => {
                        if (data.code == 0) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Registrado con Éxito'
                            })
                            this.$bvModal.hide('modal-Cargando')
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Contactar con Desarrollo',
                                text: data.error
                            })
                        }
                        this.buscarPallets()

                    })
                })
        },
        buscarOtrosPallets() {
            this.otrosPallets = []
            this.seleccionado = false
            this.$bvModal.show('modal-Cargando')
            const url = api + 'pallets/otrosPallets/' + this.Lote
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {

                    if (data.length === 0) {
                        this.$bvModal.hide('modal-Cargando')

                    } else {
                        data.forEach(element => {
                            if (element.fechaRegistro) {
                                const fecha = element.fechaRegistro.split('T')
                                element.fechaRegistro = fecha[0]

                            }
                            this.otrosPallets.push(element)
                        });
                        this.$bvModal.hide('modal-Cargando')

                    }
                    this.$bvModal.show('modal-asignarPallet')
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        buscarOtroPallet() {
            this.otrosPallets = []
            const valorBuscar = this.palletBuscar
            const url = `${api}pallets/otroPallet/${valorBuscar}/${this.Lote}`
            const reqOpciones = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {

                    if (data.length === 0) {
                        this.$bvModal.hide('modal-Cargando')

                    } else {
                        data.forEach(element => {
                            if (element.fechaRegistro) {
                                const fecha = element.fechaRegistro.split('T')
                                element.fechaRegistro = fecha[0]

                            }
                            this.otrosPallets.push(element)
                        });
                        this.$bvModal.hide('modal-Cargando')

                    }
                    this.$bvModal.show('modal-asignarPallet')
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        },
        seleccionar(datos) {
            this.palletAsignar = datos.pallet_id
            this.seleccionado = true
        },
        Asignar(){
            const lote = this.Lote
            const pallet = this.palletAsignar
            const cantidad = this.cantAsignar
            const datos = {
                idLote: lote,
                idPallet: pallet,
                cantidad: cantidad,
            }
            const reqOpciones = {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(datos)
            }
            const url = api + 'pallets/asignar'

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        throw new Error('Error en la solicitud');
                    }
                    res.json().then(data => {
                        if (data.code == 0) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Registrado con Éxito'
                            })
                            this.$bvModal.hide('modal-Cargando')
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Contactar con Desarrollo',
                                text: data.error
                            })
                        }
                        this.buscarPallets()

                    })
                }).catch(error =>{
                    console.log(error)
                    Swal.fire({
                        icon:'error',
                        title:'Error con la base de datos',
                        text: 'Avisar a Desarrollo'
                    })
                })
        },cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if (tipoUsuario == 0 || tipoUsuario == 2) {
            this.tipoUsuario = tipoUsuario
            this.Lote = sessionStorage.getItem('lote')
            this.buscarPallets()
        } else {
            window.alert('Acceso denegado')
            this.$router.push('/')
        }
    }
}
</script>

<style>
@media print {
    #zona {
        justify-content: center;
        align-items: center;
    }

    #qr {
        size: 1000px;
    }

    #ubi {
        font-size: 145px;
    }

    #zonaImpresion {
        transform: rotate(90deg);
    }
}
</style>