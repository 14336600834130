<template>
    <div id="base" style="background-color: #e7e7e9;">
        <div id="contenedorlogin" class="container-fluid col-md-6"
            style="background: white; border-radius: 10px; ">
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col"><br><img src="https://seguimiento.cmimbral.cl/assets/logo_tienda.png" width="200"
                            alt="Responsive image" class="img-fluid"><br></div>
                </div>
            </div><br>
            <h3 class="text-center" style="color: rgb(0, 58, 140); font-family: Montserrat, sans-serif;">
                <b>Sistema Bodegas</b>
            </h3>
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col">
                        <img src="../assets/logoLogin.png" width="350" alt="Responsive image" class="img-fluid">
                        <br><br>
                    </div>
                </div>
            </div>
            <div id="logCorreo">
                <form @submit.prevent="Login">

                    <div class="d-flex justify-content-center">
                        <input type="text" placeholder="Ingrese RUT" id="Rut" @input="formatoRUT" maxlength="10"
                            class="form-control form-control-lg mb-2" v-model="Rut" style="border: 1px #003A8C solid;font-size: x-large; width: 70%; text-align: center;
                            font-weight: 600;">

                    </div>
                    <div class="d-flex justify-content-center">
                        <input type="password" placeholder="Ingrese Clave" id="clave"
                            class="form-control form-control-lg mb-2" v-model="Clave" style="border: 1px #003A8C solid;font-size: x-large; width: 70%; text-align: center; 
                        font-weight: 600;">
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="row">
                            <div class="col-sm">
                                <button id="btnBuscar" class="btn btn-primary mb-1"
                                    style="background-color: rgb(0, 56, 143); border-color: rgb(237, 126, 37); font-weight: bold; font-size: large;">
                                    Iniciar Sesión
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="d-flex justify-content-center mt-2">
                <h6>¿No tienes cuenta registrada? </h6>
            </div>
            <div class="d-flex justify-content-center mb-2">
                <a style="color: #003A8C;font-weight: 700;cursor: pointer;font-size: large;" @click="Invitado()">Ingresa
                    como invitado</a>
            </div>
        </div>
    </div>
</template>

<script>
import { validate, clean, format } from 'rut.js'
import Swal from 'sweetalert2'
import { api } from '../db'

export default {
    name: 'Login',
    comments: {},
    data: function () {
        return {
            Rut: '', Clave: ''
        }
    },
    methods: {
        async Login() {
            if (validate(this.Rut)) {

                const url = api + 'usuarios/login'
                const datos = {
                    rut: this.Rut,
                    clave: this.Clave
                }
                const reqOpciones = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(datos)
                }
                console.log(url)
                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            console.log(res)
                            throw new Error('Error en la base de datos');
                        }
                        return res.json()
                    })
                    .then(data => {
                        if (data.code === 1) {
                            Swal.fire({
                                icon: 'question',
                                title: 'Rut no registrado'
                            })
                            return
                        }
                        if (data.code === 2) {
                            Swal.fire({
                                icon: 'warning',
                                title: 'Clave incorrecta'
                            })
                            return
                        }
                        if(data.code === 3){
                            Swal.fire({
                                icon:'info',
                                title:'Usuario Desactivado',
                                text: 'Hablar con Administrador'
                            })
                        }
                        sessionStorage.setItem('token', data.token)
                        sessionStorage.setItem('tipoUsuario', data.tipoUsuario)


                        if (data.tipoUsuario === 0) {
                            this.$router.push('/homeAdmin')
                        }
                        if (data.tipoUsuario === 1) {
                            this.$router.push('/registrarmovimiento')
                        }
                        if (data.tipoUsuario === 2) {
                            this.$router.push('/homerecepcion')
                        }
                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })

            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Rut no válido'
                })
            }
        },
        async formatoRUT() {
            var rut = this.Rut
            rut = clean(rut)
            this.Rut = format(rut, { dots: false })
        },
        Invitado() {
            sessionStorage.setItem('tipoUsuario', 3)
            this.$router.push('/buscarProducto')
        }
    },
    mounted() {
    }
}
</script>

<style>
#base {
  background: 
    linear-gradient(rgba(0, 58, 140, 0.3), rgba(0, 58, 140, 0.7)),
    url('../assets/fondoLogin.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

</style>