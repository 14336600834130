<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav v-if="tipoUsuario == 0" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav v-if="tipoUsuario == 1" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/RegistrarMovimiento">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/RegistrarMovimiento">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/BuscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav v-if="tipoUsuario == 2" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeRecepcion">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/BuscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <nav v-if="tipoUsuario == 3" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
        </nav>
        <div class="container-fluid col-md-11 shadow" style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Buscar Producto</span>
            </div>
            <div class="container-fluid col-md-8">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <select required class="form-control" id="selectMetodo" ref="selectMetodo" v-model="tipoBusqueda"
                            style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700; border-top-right-radius: 0; border-bottom-right-radius: 0;">
                            <option value="-1" selected hidden> Método</option>
                            <option value="0">SKU</option>
                            <option value="1">Pallet</option>
                            <option value="2">Lote</option>
                        </select>
                    </div>
                    <input required id="rack" type="number" ref="rack" class="form-control mb-2" aria-label="Large"
                        v-model="parametroBusqueda" aria-describedby="inputGroup-sizing-sm"
                        style="border: 1px #003A8C solid;">
                    <div class="input-group-prepend">
                        <button class="btn mb-2"
                            style="background-color: #003A8C;color: white; border-radius: 0 10px 10px 0;"
                            @click="buscarProducto()">
                            <b-icon icon="search" aria-hidden="true"></b-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="buscado" class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Pallet</th>
                        <th>SKU</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Bodega</th>
                        <th>Rack</th>
                        <th>Posición</th>
                        <th>Fecha de Vencimiento</th>
                        <th>Descontar</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Resultado">
                            <td>{{ datos.pallet_id }}</td>
                            <td>{{ datos.sku }}</td>
                            <td>{{ datos.descripcion }}</td>
                            <td>{{ datos.cantidad }}</td>
                            <td>{{ datos.bodega }}</td>
                            <td>{{ datos.rack }}</td>
                            <td>{{ datos.ubicacion }}</td>
                            <td>{{ datos.fechaVencimiento }}</td>
                            <td>
                                <div class="d-flex justify-content-center align-items-center">
                                    <button class="btn" @click="modalDescontar(datos)"
                                        style="background-color: #003A8C;color: white;">
                                        <b-icon icon="gear"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-modal id="modal-Descontar" size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Descontar</span>
            </div>
            <span style="color: #003A8C;font-weight: 600;">Stock actual del Pallet:</span>
            <input type="number" style="background-color: white;border:1px #003A8C solid;" class="form-control mb-2"
                v-model="cantidad" min="0">
            <span style="color: #003A8C;font-weight: 600;">Comentario:</span>
            <textarea v-model="comentario" class="form-control" id="exampleFormControlTextarea1" rows="3"
                style="border:1px #003A8C solid;background-color: white;"></textarea>
            <button @click="actualizarPallet()" class="btn mt-2 mb-2"
                style="background-color: #003A8C; color: white; font-weight: bold; width: 100%;">
                <span>Actualizar Pallet</span>
            </button>
        </b-modal>

        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header style="background-color: #FFF5E5;">
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>

<script>

import { api } from '../db'
import Swal from 'sweetalert2'
import { clean, format } from 'rut.js'

export default {
    name: 'Participantes',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            tipoUsuario: null, parametroBusqueda: null,
            Resultado: [], buscado: false, tipoBusqueda: -1,
            Pass: null, datosEditar: {}, cantidad: null,
            comentario: null, Rut: null, ultimaBusqueda: null
        }
    },
    methods: {
        async formatoRUT() {
            var rut = this.Rut
            rut = clean(rut)
            this.Rut = format(rut, { dots: false })
        },
        cerrarSesion() {
            sessionStorage.clear();
            this.$router.push('/')
        },
        buscarProducto() {

            const metodo = this.tipoBusqueda
            const busqueda = this.parametroBusqueda
            if (metodo == -1) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Seleccione un Método'
                })
                this.$bvModal.hide('modal-Cargando')
                return
            }

            if (!busqueda) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Escriba parámetro de búsqueda'
                })
                return
            }
            this.$bvModal.show('modal-Cargando')
            this.Resultado = []

            console.log(busqueda)
            //buscar por SKU: 0
            if (metodo == 0) {
                const url = api + 'buscarProducto/sku/' + busqueda
                const reqOpciones = {
                    methods: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                this.ultimaBusqueda = url
                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            throw new Error('Error en la base de datos');
                        }
                        return res.json()
                    })
                    .then(data => {
                        console.log(data)
                        if (data.length === 0) {
                            Swal.fire({
                                icon: 'question',
                                title: 'No encontrado',
                                text: 'SKU no registrado'
                            })
                        } else {
                            data.forEach(element => {
                                if (element.fechaVencimiento) {
                                    const fecha = element.fechaVencimiento.split('T')
                                    element.fechaVencimiento = fecha[0]

                                }
                                this.Resultado.push(element)
                            });
                            this.buscado = true
                        }

                        this.$bvModal.hide('modal-Cargando')

                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })
            }
            //buscar por id Pallet: 1
            else if (metodo == 1) {
                const url = api + 'buscarProducto/pallet/' + busqueda
                this.ultimaBusqueda = url
                const reqOpciones = {
                    methods: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            throw new Error('Error en la base de datos');
                        }
                        return res.json()
                    })
                    .then(data => {
                        if (data.length == 0) {
                            Swal.fire({
                                icon: 'question',
                                title: 'No encontrado',
                                text: 'Pallet no registrado'
                            })
                        } else {
                            data.forEach(element => {
                                if (element.fechaVencimiento) {
                                    const fecha = element.fechaVencimiento.split('T')
                                    element.fechaVencimiento = fecha[0]
                                }
                                this.Resultado.push(element)
                            });
                            this.buscado = true
                        }

                        this.$bvModal.hide('modal-Cargando')
                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })
            }
            //buscar por Lote Mimbral: 2
            else if (metodo == 2) {
                const url = api + 'buscarProducto/lote/' + busqueda
                this.ultimaBusqueda = url
                const reqOpciones = {
                    methods: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            throw new Error('Error en la base de datos');
                        }
                        return res.json()
                    })
                    .then(data => {
                        if (data.length === 0) {
                            Swal.fire({
                                icon: 'question',
                                title: 'No encontrado',
                                text: 'Lote no registrado'
                            })
                        } else {
                            data.forEach(element => {
                                if (element.fechaVencimiento) {
                                    const fecha = element.fechaVencimiento.split('T')
                                    element.fechaVencimiento = fecha[0]

                                }
                                this.Resultado.push(element)
                            });
                            this.buscado = true
                        }

                        this.$bvModal.hide('modal-Cargando')
                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })
            }
        },
        modalDescontar(datos) {

            const tipoUsuario = this.tipoUsuario

            this.datosEditar = datos
            this.cantidad = datos.cantidad
            console.log(tipoUsuario)
            if (tipoUsuario != 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Usuario sin Permisos',
                    text: 'Sólo se permiten administradores en este apartado'
                })
            }
            else {
                this.$bvModal.show('modal-Descontar')
            }

        },
        actualizarPallet() {

            const oldCantidad = parseInt(this.datosEditar.cantidad)
            const newCantidad = this.cantidad
            if (oldCantidad < newCantidad) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Sólo puede descontar cantidad'
                })

            } else if (oldCantidad === newCantidad) {
                Swal.fire({
                    icon: 'info',
                    title: 'No hay cambios a realizar'
                })
            } else {
                this.$bvModal.show('modal-Cargando')
                const obj = {
                    cantidad: newCantidad,
                    idPallet: this.datosEditar.pallet_id,
                    idAlmacenamiento: this.datosEditar.almacenamiento_id,
                    comentario: this.comentario
                }
                console.log(this.datosEditar)
                const url = api + 'movimientos/descontar/'

                const reqOpciones = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: sessionStorage.getItem('token')
                    },
                    body: JSON.stringify(obj)
                }

                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error al comunicarse con la API',
                                text: 'Hablar con desarrollo'
                            })
                            throw new Error('Error en la base de datos');
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Modificado Correctamente'
                        })
                        this.$bvModal.hide('modal-Cargando')
                        this.Rebuscar()
                    })
                    .catch(err => {
                        console.error('Error:', err)
                    })
            }
            this.comentario = null
            this.$bvModal.hide('modal-Descontar')
        },
        Rebuscar() {
            this.Resultado = []
            const url = this.ultimaBusqueda
            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.length === 0) {
                        Swal.fire({
                            icon: 'question',
                            title: 'No encontrado',
                            text: 'Lote no registrado'
                        })
                    } else {
                        data.forEach(element => {
                            if (element.fechaVencimiento) {
                                const fecha = element.fechaVencimiento.split('T')
                                element.fechaVencimiento = fecha[0]

                            }
                            this.Resultado.push(element)
                        });
                        this.buscado = true
                    }

                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(err => {
                    console.error('Error:', err)
                })
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        console.log(tipoUsuario)
        if (!tipoUsuario) {
            window.alert('Debe iniciar sesión')
            this.$router.push('/')
        }
        this.tipoUsuario = tipoUsuario

    }
}
</script>

<style>
th {
    background-color: #003A8C;
    color: white;
    border: 1px white solid;
}

td {
    font-weight: 500;
}
</style>