import Vue from 'vue'
import VueRouter from 'vue-router'
import RegistrarMovimiento from '../view/RegistrarMovimiento.vue'
import Bodegas from '../view/Bodegas.vue'
import BuscarProducto from '../view/BuscarProducto.vue'
import Pallets from '../view/Pallets.vue'
import Login from '../view/Login.vue'
import HomeAdmin from '../view/HomeAdmin.vue'
import Bodega from '../view/Bodega.vue'
import HomeRecepcion from '../view/HomeRecepcion.vue'
import Usuarios from '../view/Usuarios.vue'
import Informes from '../view/Informes.vue'

Vue.use(VueRouter)

const routes = [

    {
        path: '/RegistrarMovimiento',
        name: 'RegistrarMovimiento',
        component: RegistrarMovimiento,
        
    },
    {
        path: '/bodegas',
        name: 'bodegas',
        component: Bodegas,
    },
    {
        path: '/BuscarProducto',
        name: 'BuscarProducto',
        component: BuscarProducto,
    },
    {
        path: '/Pallets',
        name: 'Pallets',
        component: Pallets,
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/HomeAdmin',
        name: 'HomeAdmin',
        component: HomeAdmin,
    },
    {
        path: '/Bodega',
        name: 'Bodega',
        component: Bodega,
    },
    {
        path: '/HomeRecepcion',
        name: 'HomeRecepcion',
        component: HomeRecepcion
    },
    {
        path: '/Usuarios',
        name: 'Usuarios',
        component: Usuarios
    },
    {
        path: '/Informes',
        name: 'Informes',
        component: Informes
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


export default router