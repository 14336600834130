<template>
    <div class="content" style="background-color: #e7e7e9;">
        <nav v-if="tipoUsuario == 0" class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow " style="background-color: white; border-radius: 10px;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Informes</span>
            </div>
            <form @submit.prevent="generarInforme">
                <div class="row">
                    <div class="col-md-6">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Operador</span>
                        </div>
                        <select class="form-control form-control" id="selectOperador" ref="selectOperador"
                            v-model="Operador"
                            style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                            <option selected value="0">Todos</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Fecha Inicial</span>
                        </div>
                        <input required type="date" class="form-control" v-model="fechaInicio"
                            style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-left">
                            <span style="color: #003A8C;font-weight: 600;">Fecha Final</span>
                        </div>
                        <input required type="date" class="form-control" v-model="fechaFin"
                            style="background-color: white;color:#003A8C; border: 1px #003A8C solid;font-weight: 700;">
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-2 d-print-none">

                    <button type="submit" class="btn mb-2" style="background-color: #003A8C; color:white">
                        <span style="color: white;font-weight: 600;">Generar Informe</span>
                    </button>
                </div>
            </form>
            <div v-if="Resultado.length > 0">
                <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                    <table class="table table-bordered table-striped table-hover mb-3">
                        <thead style="position: sticky; top:0">
                            <th>Nombre</th>
                            <th>Fecha</th>
                            <th>Tipo</th>
                            <th>Aprobación</th>
                            <th>Comentario Aprobación</th>
                            <th>Pallet</th>
                            <th>Cantidad</th>
                            <th>Lote</th>
                            <th>SKU</th>
                            <th>Descripcion</th>
                            <th>Fecha de Vencimiento</th>
                        </thead>
                        <tbody>
                            <tr :key="index" v-for="(datos, index) in Resultado">
                                <td>{{ datos.nombre}}</td>
                                <td>{{ datos.fecha }}</td>
                                <td>{{ datos.tipo }}</td>
                                <td>
                                    <span v-if="datos.estado == 1">Aprobado</span>
                                    <span v-if="datos.estado == 2">Rechazado</span>
                                </td>
                                <td>{{ datos.comentario }}</td>
                                <td>{{ datos.idPallet }}</td>
                                <td>{{ datos.cantidad }}</td>
                                <td>{{ datos.lote }}</td>
                                <td>{{ datos.sku }}</td>
                                <td>{{ datos.descripcion }}</td>
                                <td>{{ datos.fechaVencimiento }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { api } from '../db'
export default {
    name: 'HomeAdmin',
    components: {

    },
    computed: {

    },
    data: function () {
        return {
            tipoUsuario: null, tipoInforme: 0, Operador: 0, fechaInicio: null,
            fechaFin: null, Resultado: []
        }
    },
    methods: {
        obtenerOperadores() {
            const selectElement = document.getElementById("selectOperador");
            this.$bvModal.show('modal-Cargando')
            const url = api + 'usuarios'

            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })

                        this.$bvModal.hide('modal-Cargando')
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    data.forEach(element => {
                        if (element.tipo === 1) {
                            const option = document.createElement("option");
                            option.value = element.rut;
                            option.text = element.nombre;
                            selectElement.appendChild(option);
                        }
                    });
                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(error => {
                    console.error('Error:', error)
                })

        },
        generarInforme() {
            const obj = {
                operador: this.Operador,
                fechaInicio: this.fechaInicio,
                fechaFin: this.fechaFin
            }

            const url = api + 'movimientos/informe'
            const reqOpciones = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(obj),
            }
            this.$bvModal.show('modal-Cargando')

            this.Resultado = []

            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    this.Resultado = data
                    this.$bvModal.hide('modal-Cargando')
                    if (data.length === 0) {
                        Swal.fire({
                            icon: 'question',
                            title: 'No hay resultados'
                        })
                    }
                })
                .catch(err => {
                    console.error('Error:', err)
                })

        },
        cerrarSesion() {
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')
        if (tipoUsuario != 0) {
            window.alert('Debe ser Administrador')
            this.$router.push('/')
        }
        this.tipoUsuario = tipoUsuario // está por si hay que tener más usuarios acá
        this.obtenerOperadores()
    },
}
</script>