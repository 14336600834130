<template>
    <div class="content">
        <nav class="navbar navbar-expand-lg d-print-none mb-2" style="background-color: #003A8C;">
            <a class="navbar-brand" href="/HomeAdmin">
                <img style="margin-left: 10px;" class="img-fluid" src="../assets/IconoMimbral.png" width="150">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                style="background-color: white;color: white;border: white;border-color: white;margin-right: 1%;">
                <span class="navbar-toggler-icon" style="border-color: white;background-color: white;color: white;"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/HomeAdmin">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="house" style="color: white;" aria-hidden="true"></b-icon>
                                Inicio</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/informes">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="file-text" style="color: white;" aria-hidden="true"></b-icon>
                                Informes</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/bodegas">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon icon="grid1x2" style="color: white;" aria-hidden="true"></b-icon>
                                Bodegas</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/HomeRecepcion">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="card-text" style="color: white;" aria-hidden="true"></b-icon>
                                Lotes</span>
                        </a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link" href="/Usuarios">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person" style="color: white;" aria-hidden="true"></b-icon>
                                Usuarios</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/buscarProducto">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">
                                <b-icon icon="search" style="color: white;" aria-hidden="true"></b-icon>
                                Buscar</span>
                        </a>
                    </li>
                    <li class="nav-item" style="cursor:pointer ;">
                        <a class="nav-link" @click="cerrarSesion()">
                            <span style="color: white;background-color: #003A8C;font-weight: 500;">

                                <b-icon scale="1.3" icon="person-x" style="color: white;" aria-hidden="true"></b-icon>
                                Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="container-fluid col-md-11 shadow" style="border-radius: 10px;background-color: white;">
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Usuarios</span>
            </div>
            <div class="table-responsive mb-2" id="tabla" style="border-radius: 10px;">
                <table class="table table-bordered table-striped table-hover mb-3">
                    <thead style="position: sticky; top:0">
                        <th>Nombre</th>
                        <th>Rut</th>
                        <th>Tipo de Usuario</th>
                        <th>Editar</th>
                    </thead>
                    <tbody>
                        <tr :key="index" v-for="(datos, index) in Usuarios">
                            <td>{{ datos.nombre }}</td>
                            <td>{{ datos.rut }}</td>
                            <td>
                                <div v-if="datos.tipo === 0">
                                    Administrador
                                </div>
                                <div v-if="datos.tipo === 1">
                                    Operador
                                </div>
                                <div v-if="datos.tipo === 2">
                                    Recepción
                                </div>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <button class="btn" v-b-modal.modal-editarUsuario @click="pasoDatos(datos)"
                                        style="background-color: #003A8C; color:white">
                                        <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn mb-2" v-b-modal.modal-crearUsuario
                style="background-color: #003A8C; color:white;width: 100%;">
                Crear Usuario Nuevo
            </button>
        </div>
        <b-modal id="modal-crearUsuario" size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Crear Nuevo Usuario</span>
            </div>

            <form @submit.prevent="Registrar">
                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Rut</span>
                </div>
                <input @input="formatoRUT" type="text" style="background-color: white;border:1px #003A8C solid;"
                    class="form-control" v-model="Rut" maxlength="10" required>
                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Nombre</span>
                </div>
                <input type="text" style="background-color: white;border:1px #003A8C solid;" class="form-control"
                    v-model="Nombre" required>

                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Tipo de Usuario</span>
                </div>
                <select class="form-control" style="background-color: white;border:1px #003A8C solid;" v-model="tipoUsuario"
                    required>
                    <option hidden selected value="no">Seleccione un tipo de usuario</option>
                    <option value="0">Administrador</option>
                    <option value="1">Operador</option>
                    <option value="2">Recepción</option>
                </select>
                <button class="btn mt-2" v-b-modal.modal-crearUsuario type="submit"
                    style="background-color: #003A8C; color:white;width: 100%;">
                    Registrar
                </button>
            </form>
        </b-modal>
        <b-modal id="modal-editarUsuario" size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center">
                <span style="color:#003A8C;font-weight: bold;font-size: x-large;">Editar Usuario</span>
            </div>

            <form @submit.prevent="Editar">
                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Rut</span>
                </div>
                <input disabled @input="formatoRUT" type="text" style="border:1px #003A8C solid;" class="form-control"
                    v-model="datosEditar.rut" maxlength="10" required>
                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Nombre</span>
                </div>
                <input type="text" style="border:1px #003A8C solid;" class="form-control" v-model="datosEditar.nombre"
                    required>

                <div class="d-flex justify-content-left">
                    <span style="color: #003A8C;font-weight: 600;">Tipo de Usuario</span>
                </div>
                <select class="form-control mb-2" style="background-color: white;border:1px #003A8C solid;"
                    v-model="datosEditar.tipo" required>
                    <option hidden selected value="no">Seleccione un tipo de usuario</option>
                    <option value="0">Administrador</option>
                    <option value="1">Operador</option>
                    <option value="2">Recepción</option>
                </select>
                <div class="d-flex justify-content-center">

                    <span v-if="datosEditar.activo == 1" style="color: #003A8C;font-weight: 600;">Desactivado</span>
                    <span v-else style="color: gray;font-weight: 600;">Desactivado</span>
                    <div class="form-check form-switch" style="margin-left: 1%;">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked v-model="activo">
                    </div>

                    <span v-if="datosEditar.activo == 0" style="color: #003A8C;font-weight: 600;">Activado</span>
                    <span v-else style="color: gray;font-weight: 600;">Activado</span>
                </div>
                <button class="btn mt-2" type="submit" style="background-color: #003A8C; color:white;width: 100%;">
                    Editar
                </button>
            </form>
        </b-modal>
        <b-modal id="modal-Cargando" centered size="md" hide-footer hide-header>
            <div class="d-flex justify-content-center align-items-center">
                <img src="../assets/animacion.gif" class="img-fluid">
            </div>
        </b-modal>
    </div>
</template>

<script>
import { validate, clean, format } from 'rut.js'
import Swal from 'sweetalert2'
import { api } from '../db'

export default {
    name: 'Usuarios',
    comments: {},
    data: function () {
        return {
            Usuarios: [], Nombre: null, Rut: null,
            tipoUsuario: 'no', datosEditar: {}
        }
    },
    methods: {

        async formatoRUT() {
            var rut = this.Rut
            rut = clean(rut)
            this.Rut = format(rut, { dots: false })
        },
        async crearPass(nombre, rut) {
            var pass = nombre[0]
            for (let i = 0; i < 5; i++) {
                pass = pass + rut[i]
            }
            console.log(pass)
            return pass
        },
        async pruebaValidez(rut, tipoUsuario) {
            if (tipoUsuario == 'no') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Debe elegir tipo de usuario'
                })
                return false
            }
            if (!validate(rut)) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Rut Inválido'
                })
                return false
            }
            return true
        },
        async Registrar() {
            this.$bvModal.show('modal-Cargando')
            const rut = this.Rut
            const nombre = this.Nombre.toUpperCase()
            const tipoUsuario = this.tipoUsuario

            if (await this.pruebaValidez(rut, tipoUsuario)) {

                const pass = await this.crearPass(nombre.toLowerCase(), rut)

                const datos = {
                    rut: rut,
                    nombre: nombre,
                    tipo: parseInt(tipoUsuario),
                    clave: pass
                }
                const url = api + 'usuarios/registro'

                const reqOpciones = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: sessionStorage.getItem('token')
                    },
                    body: JSON.stringify(datos)
                }

                fetch(url, reqOpciones)
                    .then(res => {
                        if (!res.ok) {
                            throw new Error('Error en la solicitud');
                        }
                        Swal.fire({
                            icon: 'success',
                            title: 'Registrado con éxito'
                        })
                    this.obtenerUsuarios()
                    }).catch(error => {
                        console.error(error);
                    })
            }

            this.$bvModal.hide('modal-Cargando')
            this.$bvModal.hide('modal-crearUsuario')
            this.Nombre = null
            this.Rut = null
            this.tipoUsuario = 'no'
            this.obtenerUsuarios()
        },
        async obtenerUsuarios() {
            this.Usuarios = []
            this.$bvModal.show('modal-Cargando')
            const url = api + 'usuarios'

            const reqOpciones = {
                methods: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                }
            }
            fetch(url, reqOpciones)
                .then(res => {
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })

                        this.$bvModal.hide('modal-Cargando')
                        throw new Error('Error en la base de datos');
                    }
                    return res.json()
                })
                .then(data => {
                    this.Usuarios = data
                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(error => {
                    console.error('Error:', error)
                })

        },
        async Editar() {
            this.$bvModal.show('modal-Cargando')
            const rut = this.datosEditar.rut
            const nombre = this.datosEditar.nombre.toUpperCase()
            const tipo = this.datosEditar.tipo
            const pass = await this.crearPass(nombre.toLowerCase(), rut)
            const activo = (this.activo == true) ? 0 : 1

            const url = api + 'usuarios/editar'

            const datos = {
                rut: rut,
                nombre: nombre,
                tipo: tipo,
                clave: pass,
                activo: activo
            }
            const reqOpciones = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: sessionStorage.getItem('token')
                },
                body: JSON.stringify(datos)
            }

            fetch(url, reqOpciones)
                .then(res => {
                    this.$bvModal.hide('modal-editarUsuario')
                    if (!res.ok) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error al comunicarse con la API',
                            text: 'Hablar con desarrollo'
                        })

                        throw new Error('Error en la base de datos');
                    }
                    this.obtenerUsuarios()
                    Swal.fire({
                        icon: 'success',
                        title: 'Actualizado con éxito'
                    })
                    this.$bvModal.hide('modal-Cargando')
                })
                .catch(error => {
                    console.error(error)
                })
        },
        pasoDatos(datos){
            this.datosEditar = datos
            const activo = (datos.activo == 0) ? true : false
            this.activo = activo 
        },
        cerrarSesion(){
            sessionStorage.clear();
            this.$router.push('/')
        }
    },
    mounted() {
        const tipoUsuario = sessionStorage.getItem('tipoUsuario')

        if (tipoUsuario != 0) {
            window.alert('No tiene acceso')
            this.$router.push('/')
        }
        this.obtenerUsuarios()

    }
}
</script>

<style></style>
